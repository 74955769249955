<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-subtitle>
          Select global suppression file to upload.
        </v-card-subtitle>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col>
        <v-card-title> Upload File </v-card-title>
        <v-row>
          <v-col cols="4">
            <v-file-input
              v-model="globalSuppressionFile"
              dense
              clearable
              show-size
              accept=".csv, .txt"
              :rules="globalSuppressionRules"
              label="Choose File"
              @change="clearErrors()"
            />
          </v-col>
          <v-col>
            <small
              v-if="uploadError !== ''"
              class="ml-10 mb-5"
              style="color: red"
            >
              {{ uploadError }}
            </small>
          </v-col>
        </v-row>
        <div class="font-italic pl-7 inline-block">
          Valid file extensions: .csv, .txt
        </div>
        <v-card-actions>
          <v-btn
            color="success"
            :loading="uploadingFiles"
            :disabled="globalSuppressionFile === null || !isValidTypeFile"
            @click="validateUpload()"
          >
            Upload file
          </v-btn>
          <v-btn
            color="tertiary"
            @click="cancel()"
          >
            cancel
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GlobalSuppressionFileUpload',

  props: {
    stepVal: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      results: [],
      uploadResults: [],
      valid: false,
      isSingleColumn: false,
      globalSuppressionFile: null,
      isValidTypeFile: true,
      fileData: '',
      row: '',
      row0: '',
      fileArray: [],
      globalSuppressionRules: [
        value =>
          !value ||
                value.size < 11000000 ||
                `${vm.contactType} File size should be less than 11 MB`
      ],
      resultsLoading: false,
      uploadingFiles: false,
      uploadError: ''
    }
  },

  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId'
    ]),
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    },
    isLoading () {
      return this.loadingCount > 0
    }
  },

  watch: {
    stepCounter (newValue, oldValue) {
      if (this.results.length !== 0 & newValue !== undefined) {
        this.getDrLookup()
      }
    }
  },

  created () {
  },

  methods: {
    clearErrors () {
      if (this.globalSuppressionFile !== null) {
        if (this.globalSuppressionFile.type.match('text/csv') || this.globalSuppressionFile.type.match('text/plain')) {
          this.uploadError = ''
          this.isValidTypeFile = true
        } else {
          this.uploadError = 'This is not a valid CSV or TXT file'
          this.isValidTypeFile = false
        }
      }
    },
    cancel () {
      this.$router.push({ path: 'suppression/globalSuppression' })
    },
    async validateUpload () {
      var _this = this
      this.uploadError = ''

      var reader = new FileReader()
      reader.readAsText(this.globalSuppressionFile)
      reader.onload = async () => {
        _this.fileData = reader.result
        var newLine = '\r\n'
        _this.fileArray = _this.fileData.split(newLine)
        _this.row0 = _this.fileArray[0].split(/[,\t]/).toString()
        if (_this.uploadError === '') {
          _this.uploadingFiles = true
          _this.uploadFile()
        }
      }
    },
    async uploadFile () {
      var _this = this
      _this.resultsLoading = true
      var formData = new FormData()
      var customerNowwId = this.$store.getters['simulatedCustomerNowwId']()
      formData.append('nowwId', customerNowwId)
      formData.append('files[]', _this.globalSuppressionFile)

      _this.resultsLoading = false
      _this.$emit('advanceFromStep', _this.stepVal)
      _this.$emit('row0', _this.row0)
      _this.$emit('fileArray', _this.fileArray)
      _this.$emit('fileData', _this.fileData)
      _this.uploadingFiles = false
    }
  }
}
</script>
