var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-card-subtitle", [
                _vm._v(
                  "\n        Select global suppression file to upload.\n      "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-card-title", [_vm._v(" Upload File ")]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-file-input", {
                        attrs: {
                          dense: "",
                          clearable: "",
                          "show-size": "",
                          accept: ".csv, .txt",
                          rules: _vm.globalSuppressionRules,
                          label: "Choose File"
                        },
                        on: {
                          change: function($event) {
                            return _vm.clearErrors()
                          }
                        },
                        model: {
                          value: _vm.globalSuppressionFile,
                          callback: function($$v) {
                            _vm.globalSuppressionFile = $$v
                          },
                          expression: "globalSuppressionFile"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", [
                    _vm.uploadError !== ""
                      ? _c(
                          "small",
                          {
                            staticClass: "ml-10 mb-5",
                            staticStyle: { color: "red" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.uploadError) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "font-italic pl-7 inline-block" }, [
                _vm._v("\n        Valid file extensions: .csv, .txt\n      ")
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "success",
                        loading: _vm.uploadingFiles,
                        disabled:
                          _vm.globalSuppressionFile === null ||
                          !_vm.isValidTypeFile
                      },
                      on: {
                        click: function($event) {
                          return _vm.validateUpload()
                        }
                      }
                    },
                    [_vm._v("\n          Upload file\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "tertiary" },
                      on: {
                        click: function($event) {
                          return _vm.cancel()
                        }
                      }
                    },
                    [_vm._v("\n          cancel\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }