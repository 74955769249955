import { render, staticRenderFns } from "./GlobalSuppressionFileUpload.vue?vue&type=template&id=24b0f148&"
import script from "./GlobalSuppressionFileUpload.vue?vue&type=script&lang=js&"
export * from "./GlobalSuppressionFileUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCardActions,VCardSubtitle,VCardTitle,VCol,VContainer,VDivider,VFileInput,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24b0f148')) {
      api.createRecord('24b0f148', component.options)
    } else {
      api.reload('24b0f148', component.options)
    }
    module.hot.accept("./GlobalSuppressionFileUpload.vue?vue&type=template&id=24b0f148&", function () {
      api.rerender('24b0f148', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/suppression/GlobalSuppressionFileUpload.vue"
export default component.exports